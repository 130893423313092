.skills-card{
    border:solid black 2px;   
    position: absolute;
}
.dark-mode .skills-card {
    /* background-color: #0d1117; */

}
.light-mode .skills-card {
    /* background-color: rgb(207, 207, 171) */
}
.dark-mode .skills-card h4{
    color: #0d1117;    

}
.dark-mode .skills-card h4{
    color: rgb(207, 207, 171)

}
.skills-card-image{
    max-height: 60px;
}

.skills-card-text{
    font-size: 14px;
}

.skill-card-container{
    border-radius: 7px;
    height:100px;
    width:75px;
    margin:1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.skill-card-container:hover .skills-card{
    /* transform: translate(-40px, 40px); */
    opacity: 50%;

}
.skill-card-container:hover .snippet{
    visibility: visible;
    transform: translate(0px, -160px);
    scale: 1;

}
.skill-card-container .snippet{
    position:absolute;
    visibility: hidden;
    transition: 200ms ease-in-out;
    scale: 0.1;

}
.snippet-image{
    max-height: 200px;
    border-radius: 7.5px;

}