@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&display=swap");
* {
  color: rgb(35, 35, 35);
}
.dark-mode * {
  color: rgb(207, 207, 171);
}

@media only screen and (max-width: 600px) {
  .hero-section{
    margin-top:0px;
  }
  h1 * {
    font-size: 80px;
    font-weight: 300;
  }
  .hero-section .subtext {
    font-size: 40px;
  }

}

.hero-section {
  font-weight: 300;
  text-align: center;
  margin-top: 17vh;
}
.light-text {
  font-weight: 100;
}
h1 {
  font-size: 160px;
  font-weight: 700;
}
.subtext {
  font-size: 80px;
}

.dark {
  background-color: rgb(35, 35, 35);
  transition: 0.3s;
}

.switch {
  width: 80px;
  height: 40px;
  background-color: rgb(35, 35, 35);
  border-radius: 20px;
  position: relative;
}
.dark .switch {
  background-color: rgb(255, 255, 255);
}
.toggle {
  width: 30px;
  height: 30px;
  transition: 0.3s;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 5px;
  left: 5px;
}
.dark .switch .toggle {
  background-color: rgb(35, 35, 35);
}
body {
  transition: 0.3s;
}

.moon-container {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}
.dark .moon {
  box-shadow: 4px 4px 0 0 rgb(35, 35, 35);
  top: 5px;
}
.moon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: relative;
  top: 10px;
  left: 45px;
  box-shadow: 8px 0 0 -4px rgb(255, 255, 255), -8px 0 0 -4px rgb(255, 255, 255),
    0 8px 0 -4px rgb(255, 255, 255), 0 -8px 0 -4px rgb(255, 255, 255),
    6px -6px 0 -4px rgb(255, 255, 255), -6px -6px 0 -4px rgb(255, 255, 255),
    -6px 6px 0 -4px rgb(255, 255, 255), 6px 6px 0 -4px rgb(255, 255, 255);
}

.dark .toggle {
  transform: translate(40px);
}
.dark .moon-container {
  transform: translate(-40px);
}

.sun {
  width: 8px;
  height: 8px;
}

.hero-underline {
  height: 6px;
  width: 80%;
  margin: auto;
  margin-top: 10px;
  border-radius: 3px;
}

.dark-mode .hero-underline {
  background-color: white;
}
.light-mode .hero-underline{
  background-color: black;
}
